import React, { useState, useEffect, useRef } from 'react';

import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';

import './PhotoCrop.css';

import { SvgClose } from 'components/SvgFile';

import { Reg_PanelAltPhoto } from 'services/RegisterData';

import { GetUserData } from 'interface/Users';
import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from 'interface/PopUp';

export default function PopUp_PhotoCrop(props){

    const positionImg = useRef();
    
    const [ userId, setUserId ]       = useState(GetUserData('id'));
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ typeCrop, setTypeCrop ]   = useState(false);

    const [ proportionX, setProportionX ] = useState(16);
    const [ proportionY, setProportionY ] = useState(16);

    const [ crop, setCrop ] = useState({
        unit: '%', // Can be 'px' or '%'
        aspect: proportionX / proportionY,
        x: 0,
        y: 0,
        width: 0,
        height: 0
    });

    async function cropImageNow (){
        if(modalData.type == 'recort'){
            if(typeCrop){
                modalData.RegisterCropData(
                    {
                        "type"      : typeCrop, 
                        "file"      : modalData.file,  
                        "positionX" : crop.x, 
                        "positionY" : crop.y, 
                        "width"     : crop.width, 
                        "height"    : crop.height,
                        "showWidth" : positionImg.current.clientWidth,
                        "widthReal" : positionImg.current.naturalWidth
                    }
                );
            }else {
                modalData.RegisterCropData(
                    {
                        "type" : typeCrop, 
                        "file" : modalData.file,  
                        "positionX" : 0, 
                        "positionY" : 0, 
                        "width" : 0, 
                        "height" : 0,
                        "showWidth" : 0,
                        "widthReal" : 0
                    }
                );
            }
        }else if(modalData.type == 'alt'){
            props.setLoading(true);            
            
            Reg_PanelAltPhoto(userId, modalData.idPage, modalData.showFile, crop.x, crop.y, crop.width, crop.height, positionImg.current.clientWidth, positionImg.current.naturalWidth, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        }
        SetModalState('PhotoCrop', false);
    };

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }
 
    // Starts cropping at 9/16 ratio centered on image
    function onImageLoad(e) {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
        let crop = "";
        crop = centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                }
            ),
            width,
            height
        )  
        setCrop(crop);
    }

    function ClosePopUp(){
        SetModalState('PhotoCrop', false);
    }

    useEffect(()=>{
        RegisterModalData('PhotoCrop', setModaldata);
        RegisterModalObserver('PhotoCrop', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            if(modalData.type === 'alt'){
                setTypeCrop(true);
            }
        }
    }, [showPopUp]);

    return (
        (showPopUp ? 
            <div className="PopUp">
                <div className="all photo_crop">
                    <div className="div_data type_div">
                        <div className="title">Recorte de foto</div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <SvgClose color="#F00000" className="icons" />
                        </div>
                    </div>
                    <div className="div_data" style={ { paddingTop: 0 } }>
                        <div className="content">
                            {
                                modalData.type === 'alt' ? null :
                                <div className="div_type_crop">
                                    <div className={ typeCrop == false ? "type_crop crop_open" : "type_crop" } onClick={ ()=>{ setTypeCrop(false) } }>Sem recorte</div>
                                    <div className={ typeCrop ? "type_crop crop_open" : "type_crop" } onClick={ ()=>{ setTypeCrop(true) } }>Com recorte</div>
                                </div>
                            }
                            {
                                typeCrop ? 
                                <>                                    
                                    {
                                        crop.width > 0 ? 
                                        <div className="new_block_text" onClick={ ()=>{ cropImageNow() } } style={ { marginTop: modalData.type === 'alt' ? '0px': '30px' } }>Salvar</div> :
                                        <div className="new_block_text" style={ { marginTop: modalData.type === 'alt' ? '0px': '30px' } }>É necessário recortar a imagem para salvar</div>
                                    }

                                    <div className="">Clique na imagem e arrasta para realizar o recorte</div>

                                    <div className="image_crop">
                                        <ReactCrop crop={ crop } onChange={ setCrop } aspect={ proportionX / proportionY }>
                                            <img ref={ positionImg } src={ modalData.showImg } onLoad={ onImageLoad } className="image" />
                                        </ReactCrop>
                                    </div>
                                </> :
                                <>
                                    <div className="new_block_text" onClick={ ()=>{ cropImageNow() } }>Salvar</div>
                                    <div className="image_crop">
                                        <img src={ modalData.showImg } ref={ positionImg } className="image" />
                                    </div>
                                </>
                            }

                        </div>
                    </div>
                </div>
            </div> 
        : <React.Fragment></React.Fragment>)
    );
}