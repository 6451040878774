import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Reg_Product } from "services/RegisterData";

import { SvgDelete, SvgImg, SvgSave, SvgSetaRight } from "components/SvgFile";

export default function Page_Product_Details(props){

    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('product'));
    const [ options, setOptions ]   = useState(GetDataPage('panel_data'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ checked, setChecked ] = useState(CheckedInf());

    const [ title, setTitle ]       = useState(InitialData('title'));
    const [ text, setText ]         = useState(InitialData('text'));
    const [ type, setType ]         = useState(InitialData('type'));
    const [ typeName, setTypeName ] = useState(InitialData('type_name'));
    const [ newType, setNewType ]   = useState(InitialData(''));

    const [ file, setFile ]           = useState(InitialData('file'));
    const [ positionX, setPositionX ] = useState('');
    const [ positionY, setPositionY ] = useState('');
    const [ width, setWidth ]         = useState('');
    const [ height, setHeight ]       = useState('');
    const [ showWidth, setShowWidth ] = useState('');
    const [ widthReal, setWidthReal ] = useState('');
    const [ status, setStatus ]       = useState(false);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            if(type == 'type_name'){
                if(newData[type] !=''){
                    return newData[type];
                }else {
                    return "Não definido";
                }
            }
            return newData[type];
        }
        if(type == 'type_name'){
            return 'Selecione uma opção...'
        }
        return '';
    }

    function CheckedInf(){
        if(GetUserData('access') == 1){
            return true;
        }else {
            const newData = GetUserData('page').find(item => item.page == 'Produtos');
            if(newData.type == 'Editar'){        
                return true;
            }else {
                return false;
            }
        }
    }

    function AddFile(value){
        if(value){
            setFile(value.file);
            setPositionX(value.positionX);
            setPositionY(value.positionY);
            setWidth(value.width);
            setHeight(value.height);
            setShowWidth(value.showWidth);
            setWidthReal(value.widthReal);
            setStatus(true);
        }else {
            setFile('');
            setPositionX('');
            setPositionY('');
            setWidth('');
            setHeight('');
            setShowWidth('');
            setWidthReal('');
            setStatus(false);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function PopUpCrop(value){
        SetModalData('PhotoCrop', { "file": value, "type": "recort", "showImg": URL.createObjectURL(value), "RegisterCropData": AddFile });
        SetModalState('PhotoCrop', true);
    }

    function ListSelect(value){
        const newData = [{ "value": "Adicionar novo", "label": "Adicionar novo" }];
        options.map((key, index)=>{
            if(key.type == value){
                newData.push({
                    "value": key.id, "label": key.name
                });
            }
        });
        return newData;
    }

    function ReturnPage(){
        SetListPag('currentPage', 'product');
        SetListPag('currentPageId', 'remuve');
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Product(userId, idPage, title, text, file, type, newType, positionX, positionY, width, height, showWidth, widthReal, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('product', setDataPage);
        RegisterDataPage('panel_data', setOptions);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('product'));
        setOptions(GetDataPage('panel_data'));
        setIdPage(GetListPag('currentPageId'));

        setTitle(InitialData('title'));
        setText(InitialData('text'));
        setType(InitialData('type'));
        setTypeName(InitialData('type_name'));
        setNewType('');
        
        setFile(InitialData('file'));
        setPositionX('');
        setPositionY('');
        setWidth('');
        setHeight('');
        setShowWidth('');
        setWidthReal('');
        setStatus(false);
    }, [dataPage, idPage]);

    return(
        <div className="Page_Product_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Produtos
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <div className="title_page">Detalhes</div>
                    <div className="list_opt_alt_page">
                        {
                            checked ?
                            <button className="div_add_new_data">
                                <div className="new_data_add">
                                    <SvgSave color="#ffffff" className="icons" />
                                </div>
                                <div className="name_btn_add">salvar</div>
                            </button> : null
                        }
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Dados do produto</div>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input">
                            <Select className="select_type" defaultOptions options={ ListSelect('type') } value={ { "value": type, "label": typeName } } onChange={ (e)=> { setType(e.value); setTypeName(e.label) } } placeholder="..." />
                            <span className="name_input">Tipo</span>
                        </div> 
                        {
                            type != 'Adicionar novo' ? null :
                            <div className="div_input space_div">
                                <input type="text" className="" onChange={ (e)=>{ setNewType(e.target.value) } } value={ newType } maxLength="40" required />
                                <span className="name_input">Nome do novo tipo</span>
                            </div> 
                        }
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" className="data_input" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" required />
                            <span className="name_input">Título</span>
                        </div>                        
                        <span className="div_input div_add_img">
                            <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                    {
                                        file !='' ?
                                        <input type="file" onChange={ (e)=>{ PopUpCrop(e.target.files[0]) } } accept="image/*" /> : 
                                        <input type="file" onChange={ (e)=>{ PopUpCrop(e.target.files[0]) } } accept="image/*" required />
                                    }
                                </label>
                            </div>
                            {
                                file !='' ?
                                <>
                                    {
                                        checked ?
                                        <div className="delete_img_add" onClick={ ()=>{ AddFile('') } }>
                                            <SvgDelete className="icons" color="#ffffff" />
                                        </div> : null
                                    }
                                    {
                                        status == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                            <img alt="img" src={ URL.createObjectURL(file) } className="icon_file" />
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "product/" + file) } }>
                                            <img alt="img" src={ "./assets/product/thumbnail/thumb_" + file } className="icon_file" />
                                        </div>
                                    }
                                </>
                                : null
                            }
                        </span>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Descrição</div>
                            <JoditEditor className="show_textarea" config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}