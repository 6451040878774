
let DataPage = {
    "upload_site" : "",
    "access_dash" : [],

    "name_menu" : [],
    "site"      : [],
    "product"  : [],

    "panel"         : [],
    "panel_data"    : [],
    "list_coverage" : [],

    "broadcast"   : [],

    "entry"   : [],
    "exit"    : [],
    "balance" : [],

    "list_payment" : [],
    "list_bank"    : [],
    "list_origin"  : [],
};

let NotifyDataPage = {
    "upload_site" : [],
    "access_dash" : [],

    "name_menu" : [],
    "site"      : [],
    "product"  : [],

    "panel"         : [],
    "panel_data"    : [],
    "list_coverage" : [],
    
    "broadcast"   : [],

    "entry"   : [],
    "exit"    : [],
    "balance" : [],

    "list_payment" : [],
    "list_bank"    : [],
    "list_origin"  : [],
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["upload_site"].forEach(element => { element(value["upload_site"]); });
    NotifyDataPage["access_dash"].forEach(element => { element(value["access_dash"]); });

    NotifyDataPage["name_menu"].forEach(element => { element(value["name_menu"]); });
    NotifyDataPage["site"].forEach(element => {      element(value["site"]); });
    NotifyDataPage["product"].forEach(element => {   element(value["product"]); });

    NotifyDataPage["panel"].forEach(element => {          element(value["panel"]); });
    NotifyDataPage["panel_data"].forEach(element => {     element(value["panel_data"]); });
    NotifyDataPage["list_coverage"].forEach(element => {  element(value["list_coverage"]); });

    NotifyDataPage["entry"].forEach(element => {   element(value["entry"]); });
    NotifyDataPage["exit"].forEach(element => {    element(value["exit"]); });
    NotifyDataPage["balance"].forEach(element => { element(value["balance"]); });

    NotifyDataPage["list_payment"].forEach(element => { element(value["list_payment"]); });
    NotifyDataPage["list_bank"].forEach(element => {    element(value["list_bank"]); });
    NotifyDataPage["list_origin"].forEach(element => {  element(value["list_origin"]); });


}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
